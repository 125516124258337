<template>
    <el-dialog
        :title="$t('edit')"
        :visible.sync="modalEditHistory"
        :before-close="closeModal"
        width="500px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
        @open="bindData"
    >
        <el-row>
            <el-col :span="20" :offset="2" class="flex justify-center py-12">
                <el-form
                    v-loading="$wait.is('updating')"
                    :model="selectedHistory"
                    :inline="true"
                    label-position="top"
                    @submit.native.prevent
                    @keyup.native.enter="updateHistory"
                >
                    <el-form-item :label="$t('hours')">
                        <el-input-number v-model="hours" :min="0" :max="10" />
                    </el-form-item>
                    <el-form-item :label="$t('minutes')">
                        <el-input-number v-model="minutes" :min="0" :max="59" :step="5" />
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('updating')" @click="updateHistory">{{ $t('common.update') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalEditHistory: {
            type:    Boolean,
            default: false,
        },
        selectedHistoryProp: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            hours:           0,
            minutes:         0,
            selectedHistory: {},
        };
    },

    created() {
        this.bindData();
    },

    methods: {
        bindData() {
            this.selectedHistory = JSON.parse(JSON.stringify(this.selectedHistoryProp));
            this.minutes = this.selectedHistory.duration - Math.floor(this.selectedHistory.duration / 60) * 60;
            this.hours = Math.floor(this.selectedHistory.duration / 60);
        },

        async updateHistory() {
            this.$wait.start('updating');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/${this.selectedHistory.uuid}`, {
                    duration: (this.hours * 60) + this.minutes,
                });
                this.$wait.end('updating');
                this.$notify.success({ title: this.$t('common.success') });
                this.closeModal();
                this.$emit('refresh');
            } catch (error) {
                this.$wait.end('updating');
                this.closeModal();
            }
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
